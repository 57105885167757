import React, { useEffect } from 'react'
import IframeComm from "react-iframe-comm";
import { createNewWallet, poaSign, poaNormalSign, ethContractSign } from './Blockchain'
import { isValidAddress } from './RuleUtils'
import db from './model/db'
import { hashPassword, paddingLeftZero, encryptMsg, decryptMsg, encryptPky } from './Utils'

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import xmas from './images/xmas.jpg';

import { getMainUrl } from './api/getMainnetInfuraApi'

var height = document.documentElement.clientHeight
var width  = window.screen.width

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Game = () => {

  var url = new URL(window.location.href)
  var admin_enable = url.searchParams.get("admin");
  // var mainUrl = ""
  // if(admin_enable) {
  //   mainUrl = "https://poa-admin.df.r.appspot.com"
  // } else {
  //   mainUrl = "https://wallet-dapp-293009.df.r.appspot.com/"+"?"+url.searchParams
  // }
 
  // var mainUrl = "https://aqa-wallet-299701.df.r.appspot.com"
  // var mainUrl = "https://phoenix-exchange-wallet.df.r.appspot.com"
  // var mainUrl = "https://hiyes-wallet-95704.df.r.appspot.com"
  // var mainUrl = "https://w1-wallet-18360.df.r.appspot.com"
  // var mainUrl = "https://mas-wallet-30010.df.r.appspot.com"

  // var mainUrl = "https://sport-fi-wallet.azurefd.net/"
  // var mainUrl = "https://sport-ngn-wallet.azurefd.net/"
    // var mainUrl = "https://bridge-web-wallet.azurefd.net/"
  // var mainUrl = "https://bridge-art-wallet.azurefd.net/"
  // var mainUrl = "http://192.168.0.120:3001"
  // var mainUrl = "http://172.16.200.209:3001"

  const [mainUrl, setMainUrl] = React.useState("");
  const [allow, setAllow] = React.useState("");

  const [adShow, setAdShow] = React.useState(true);

  const [isJsBridgeInit, setIsJsBridgeInit] = React.useState(false);

  const [openDappDialog, setOpenDappDialog] = React.useState(false);
  const [dappUrl, setDappUrl] = React.useState("");
  const [redirectMode, setRedirectMode] = React.useState(false);

  const requestAppCloseChat = () => {
    if (!isJsBridgeInit) {
      return
    }
    window.WebViewJavascriptBridge.callHandler(
        "jsCallAndroid",
        {method:"closeChat", data:""},
        (responseData) => {
        }
    );
  }

  const requestAppSwitchBottomLayout = (status) => {

    if (!isJsBridgeInit) {
      return
    }
    window.WebViewJavascriptBridge.callHandler(
        "jsCallAndroid",
        {method:"bottomLayout", data:status},
        (responseData) => {
            console.log('test99 responseData  '+responseData)
        }
    );
  }

  const requestWebAuth = () => {
    var createCredentialDefaultArgs = {
      publicKey: {
          // Relying Party (a.k.a. - Service):
          rp: {
              name: "Acme"
          },
  
          // User:
          user: {
              id: new Uint8Array(16),
              name: "john.p.smith@example.com",
              displayName: "John P. Smith"
          },
  
          pubKeyCredParams: [{
              type: "public-key",
              alg: -7
          }],
  
          authenticatorSelection: {
            authenticatorAttachment: "platform",
          },

          attestation: "direct",
  
          timeout: 60000,
  
          challenge: new Uint8Array([ // must be a cryptographically random number sent from a server
              0x8C, 0x0A, 0x26, 0xFF, 0x22, 0x91, 0xC1, 0xE9, 0xB9, 0x4E, 0x2E, 0x17, 0x1A, 0x98, 0x6A, 0x73,
              0x71, 0x9D, 0x43, 0x48, 0xD5, 0xA7, 0x6A, 0x15, 0x7E, 0x38, 0x94, 0x52, 0x77, 0x97, 0x0F, 0xEF
          ]).buffer
        }
    };

    var getCredentialDefaultArgs = {
      publicKey: {
          timeout: 60000,
          // allowCredentials: [newCredential] // see below
          challenge: new Uint8Array([ // must be a cryptographically random number sent from a server
              0x79, 0x50, 0x68, 0x71, 0xDA, 0xEE, 0xEE, 0xB9, 0x94, 0xC3, 0xC2, 0x15, 0x67, 0x65, 0x26, 0x22,
              0xE3, 0xF3, 0xAB, 0x3B, 0x78, 0x2E, 0xD5, 0x6F, 0x81, 0x26, 0xE2, 0xA6, 0x01, 0x7D, 0x74, 0x50
          ]).buffer
      },
    };

    navigator.credentials.create(createCredentialDefaultArgs)
    .then((cred) => {
        console.log("NEW CREDENTIAL", cred);

        // normally the credential IDs available for an account would come from a server
        // but we can just copy them from above...
        var idList = [{
            id: cred.rawId,
            transports: ["platform"],
            type: "public-key"
        }];
        getCredentialDefaultArgs.publicKey.allowCredentials = idList;
        return navigator.credentials.get(getCredentialDefaultArgs);
    })
    .then((assertion) => {
        console.log("ASSERTION", assertion);
        alert("ASSERTION "+assertion)

    })
    .catch((err) => {
        console.log("ERROR", err);
        alert("err "+err)
    });
  }

  const connectWebViewJavascriptBridge = (callback) => {
    if (window.WebViewJavascriptBridge) {
        callback(window.WebViewJavascriptBridge);
    } else {
        document.addEventListener(
            "WebViewJavascriptBridgeReady",
            function() {
                callback(window.WebViewJavascriptBridge);
            },
            false
        );
    }
}

  // useEffect(
  //   () => {
  //     const pathname = window.location.pathname
  //     if (pathname && pathname.length>1) {
  //       console.log("path "+pathname.substr(1))

  //       // if(pathname=="/defi") {
  //       //   setMainUrl("https://n1-defi.azurefd.net/")
  //       // } else if(pathname=="/ad") {
  //       //   setMainUrl("https://n1-ad.azurefd.net/")
  //       // }

  //       // var buf = Buffer.from(pathname.substr(1), "hex");
  //       // var redirectUrl = buf.toString("utf8");

  //       // console.log("path "+redirectUrl)

  //       // setDappUrl(redirectUrl)
  //       // setOpenDappDialog(true)
  //       // setRedirectMode(true)
  //     } else {
  //       // setMainUrl("http://localhost:3001")
  //       setMainUrl("https://super-wallet.azurefd.net/wallet/")
  //     }

  //     connectWebViewJavascriptBridge((bridge)=>{
  //       setIsJsBridgeInit(true)
  //     })
  //   },[]
  // )

  useEffect(
    () => {
      getMainUrl(window.location.href).then(result=>{
        setMainUrl(result)
      })
    },[]
  )

  return (
    <React.Fragment>
      <Box style={{
        width: "100%",
        height: height,
        // backgroundColor:"#000000",
      }}>
       
        {/* <Box style={{
          width:'100%',
          position: 'fixed',
          height: height,
          top: 'auto',
        }}>
            
        </Box> */}
        
        {/* {
          adShow?
          <Box style={{
            width: "100%",
            height: height,
            position: 'fixed',
            top: 80,
            flexDirection: "row",
            display: 'flex',
            justifyContent:'center',
            // alignItems: 'center',
          }}>
            <img style={{
              width:width>444?444:width,
              height:width>444?417:width*0.939,
              
            }} src={xmas} alt="Background"/>
          </Box>
          :
          <Box/>
        } */}

        <IframeComm
              attributes={{
                id: "iframe",
                src: mainUrl,
                position: 'fixed',
                width:'100%',
                top: 'auto',
                height: height,
                allow: "camera"
              }}
              postMessageData={"test99 hello iframe"}
              handleReady={()=>{
                console.log("test99 onReady");
                setAdShow(false)
              }}
              handleReceiveMessage={(event)=>{
                if (event.data.method) {
                  var iframe = document.getElementById('iframe').contentWindow

                  console.log("test99 onReceiveMessage "+JSON.stringify(event.data))

                  const request = event.data.data

                  switch (event.data.method) {
                    case "getWallet":
                      const poaAddress = localStorage.getItem('poaAddress_aqn')
                      if (poaAddress && isValidAddress(poaAddress)) {
                        iframe.postMessage({method:"returnWallet", data:poaAddress}, '*');
                      } else {
                        iframe.postMessage({method:"returnWallet", data:"EMPTY"}, '*');
                      }
                      break
                    case "getLocalStorage":
                      var localData = localStorage.getItem(request)
                      iframe.postMessage({method:"returnLocalStorage", data:localData}, '*');
                      break
                    case "getLocation":
                      var location = window.location.href
                      iframe.postMessage({method:"returnLocation", data:location}, '*');
                      break
                    case "encryptMsg":
                      var msg = encryptMsg(request.input, request.key)
                      iframe.postMessage({method:"returnEncryptMsg", data:msg}, '*');
                      break
                    case "decryptMsg":
                      var msg = decryptMsg(request.input, request.key)
                      iframe.postMessage({method:"returnDecryptMsg", data:msg}, '*');
                      break
                    case "getTgId":
                      iframe.postMessage({method:"returnTgId", data:"0"}, '*');
                      break
                    case "getAuthAddress":

                      db.table_wallet.where('chainType').anyOf(1).first((wallet)=>{            
                        if(wallet) {
                          iframe.postMessage({method:"returnAuthAddress", data:wallet.address}, '*');
                        } else {
                          iframe.postMessage({method:"returnAuthAddress", data:""}, '*');
                        }
                      })
                      
                      break
                    case "generatePky":
                      createNewWallet().then(ethAddress=>{
                        console.log('createNewWallet '+ethAddress)

                        iframe.postMessage({method:"returnAuthAddress", data:ethAddress}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "setPoaAddress":
                      localStorage.setItem('poaAddress_aqn', request);
                      break
                    case "signWalletData":
                      poaSign(request).then(signature=>{
                        if(signature == "") {
                          return
                        }
                        console.log('ethSign '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnWalletSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "signEthWalletContractData":
                      ethContractSign(request.signData, request.toAddress).then(signature=>{
                        if(signature == "") {
                          return
                        }
                        // console.log('signEthWalletContractData '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnEthWalletContractSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "signNormalData":
                      poaNormalSign(request.signData, request.toAddress).then(signature=>{
                        if(signature == "") {
                          return
                        }
                        // console.log('ethNormalSign '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnNormalSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "launchDapp":
                      setDappUrl(request)
                      setOpenDappDialog(true)
                      break
                    case "closeDapp":
                      if (redirectMode) {
                        requestAppCloseChat()
                      } else {
                        setOpenDappDialog(false)
                      }
                      break
                    case "launchChat":
                      
                      break
                    case "appAuthorize":
                      requestWebAuth()
                      break
                  }
                }
              }}
        />
        </Box>
    </React.Fragment>


  );
}

export default Game;
